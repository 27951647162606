import React from "react";
import { Search } from "lucide-react";
import { Button } from "../components/ui/Button.tsx";
import { Input } from "../components/ui/Input.tsx";
import { Badge } from "../components/ui/Badge.tsx";
import { Card, CardContent } from "../components/ui/Card.tsx";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../components/ui/Avatar.tsx";
import stripeLogo from "../assets/stripe.jpeg";
import shopifyLogo from "../assets/shopify.png";

export default function AppPage() {
  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header */}
      {/* <header className="border-b bg-white">
        <div className="container mx-auto flex h-16 items-center px-4">
          <div className="flex items-center space-x-4">
            <span className="text-xl font-semibold">stripe</span>
            <span className="text-sm text-muted-foreground">
              APP MARKETPLACE
            </span>
          </div>
          <div className="ml-auto flex items-center space-x-4">
            <div className="relative">
              <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-gray-500" />
              <Input
                className="w-[300px] pl-8"
                placeholder="Search apps"
                type="search"
              />
            </div>
            <Button variant="ghost">Build an app</Button>
            <Button variant="ghost">Dashboard</Button>
            <Avatar>
              <AvatarImage
                alt="User"
                src="/placeholder.svg?height=32&width=32"
              />
              <AvatarFallback>BC</AvatarFallback>
            </Avatar>
          </div>
        </div>
      </header> */}

      <div className="container mx-auto flex gap-8 px-4 py-8">
        {/* Sidebar */}
        <aside className="w-64 space-y-4">
          <h2 className="text-lg font-semibold">Categories</h2>
          <nav className="space-y-2">
            {[
              "All",
              "Accounting",
              "Affiliate and referrals",
              "Billing",
              "Climate",
              "Commerce",
              "Compliance",
              "Data and analytics",
              "Financial services",
              "Fraud",
              "Marketing and sales",
              "Payment gateways",
              "Productivity",
              "Revenue optimization",
              "Support",
              "Tax",
            ].map((category) => (
              <a
                key={category}
                href="#"
                className="block text-sm text-gray-600 hover:text-gray-900"
              >
                {category}
              </a>
            ))}
          </nav>

          <div className="pt-4">
            <h3 className="mb-2 text-lg font-semibold">Request an app</h3>
            <p className="mb-4 text-sm text-gray-600">
              Can't find what you're looking for? Let us know.
            </p>
            <Button variant="outline" className="w-full">
              Submit a request
            </Button>
          </div>
        </aside>

        {/* Main Content */}
        <main className="flex-1 space-y-8">
          <section>
            <h2 className="mb-2 text-2xl font-semibold">Featured apps</h2>
            <p className="mb-6 text-gray-600">
              Streamline work, save time, and share context across tools.
            </p>
            <div className="grid gap-6 md:grid-cols-2">
              {[
                {
                  name: "Stripe",
                  description: "Seamlessly collect online payment",
                  icon: stripeLogo,
                  category: "Payment gateways",
                },
                // {
                //   name: "Square",
                //   description: "Collect payments in person for merchants.",
                //   icon: "/placeholder.svg?height=40&width=40",
                //   category: "Payment gateways",
                // },
                {
                  name: "Shopify",
                  description: "Easily start and run your e-commerce business",
                  icon: shopifyLogo,
                  category: "Commerce",
                },
                // {
                //   name: "Intuit Tax",
                //   description:
                //     "Fully managed solution for tax registrations, reporting, and filing",
                //   icon: "/placeholder.svg?height=40&width=40",
                //   category: "Tax",
                // },
              ].map((app) => (
                <Card key={app.name} className="overflow-hidden">
                  <CardContent className="p-6">
                    <div className="flex items-start gap-4">
                      <Avatar className="h-10 w-10">
                        <AvatarImage alt={app.name} src={app.icon} />
                      </Avatar>
                      <div className="space-y-1">
                        <h3 className="font-semibold">{app.name}</h3>
                        <p className="text-sm text-gray-600">
                          {app.description}
                        </p>
                        <Badge variant="secondary" className="mt-2">
                          {app.category}
                        </Badge>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              ))}
            </div>
          </section>

          {/* <section>
            <h2 className="mb-2 text-2xl font-semibold">Grow your business</h2>
            <p className="mb-6 text-gray-600">
              Optimize your funnel and increase customer engagement.
            </p>
            <div className="grid gap-6 md:grid-cols-2">
              {[
                {
                  name: "Chargeblast",
                  description: "Eliminate your chargebacks",
                  icon: "/placeholder.svg?height=40&width=40",
                  category: "Fraud",
                },
                {
                  name: "FlyCode",
                  description:
                    "Maximize Subscription Revenue with Dunning & Payment optimization AI",
                  icon: "/placeholder.svg?height=40&width=40",
                  category: "Revenue optimization",
                },
              ].map((app) => (
                <Card key={app.name} className="overflow-hidden">
                  <CardContent className="p-6">
                    <div className="flex items-start gap-4">
                      <Avatar className="h-10 w-10">
                        <AvatarImage alt={app.name} src={app.icon} />
                        <AvatarFallback>{app.name[0]}</AvatarFallback>
                      </Avatar>
                      <div className="space-y-1">
                        <h3 className="font-semibold">{app.name}</h3>
                        <p className="text-sm text-gray-600">
                          {app.description}
                        </p>
                        <Badge variant="secondary" className="mt-2">
                          {app.category}
                        </Badge>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              ))}
            </div>
          </section> */}
        </main>
      </div>
    </div>
  );
}
