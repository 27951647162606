import React from "react";

export const Avatar: React.FC<{ children: React.ReactNode; className?: string }> = ({ children, className = '' }) => {
    return <div className={`relative inline-block ${className}`}>{children}</div>
  }
  
export const AvatarImage: React.FC<{ src: string; alt: string }> = ({ src, alt }) => {
return <img src={src} alt={alt} className="h-full w-full object-cover rounded-full" />
}

export const AvatarFallback: React.FC<{ children: React.ReactNode }> = ({ children }) => {
return (
    <div className="flex h-full w-full items-center justify-center rounded-full bg-gray-100 text-gray-600">
    {children}
    </div>
)
}