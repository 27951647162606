import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "../components/ui/Button.tsx";
import {
  ChevronRight,
  CreditCard,
  Menu,
  RefreshCcw,
  Wallet,
  HandCoins,
  Landmark,
  CodeXml,
  StickyNote,
} from "lucide-react";
import axios from "axios";

export default function MintUSDCPage() {
  const [balance, setBalance] = useState("0.00");
  const [selectedOption, setSelectedOption] = useState("bank");
  // Mint API
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const address = "0x1689Cd941ac872a23cBeD77D58A4A7Ce156BabDa";

  const handleBalanceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Only allow numbers and one decimal point
    const value = e.target.value.replace(/[^0-9.]/g, "");
    if (value.split(".").length > 2) return;
    setBalance(value);
    console.log("balance updated.");
  };

  // API Call: Mint USDC
  const postMintUSDC = async (amount: string, address: string) => {
    setLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/mint`,
        {
          amount: 1,
          destinationAddress: "0x2BAcd721d631a89BE1c3545dA82bf62Fe532c531",
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": `${process.env.REACT_APP_BE_AUTH_TOKEN}`,
          },
        }
      );
      console.log(`api call succeed. ${JSON.stringify(response.data)}`);
      setData(response.data);
    } catch (err) {
      console.error("Error posting data:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white">
      <main className="container mx-auto p-4">
        <div className="rounded-lg border bg-white p-6 shadow-sm">
          <div className="mb-6">
            <div className="text-sm text-neutral-500 mb-1">USDC Amount</div>
            <div className="flex items-center justify-between mb-6">
              <div className="w-1/2 relative">
                <span className="absolute text-2xl font-bold left-3 top-1/2 transform -translate-y-1/2">
                  $
                </span>
                <input
                  type="text"
                  value={balance}
                  onChange={handleBalanceChange}
                  className="text-4xl font-bold pl-8 pr-3 py-2 w-full bg-transparent focus:outline-none"
                  aria-label="Account balance"
                />
              </div>

              <Button
                variant="outline"
                size="sm"
                onClick={() => postMintUSDC(balance, address)}
                disabled={loading}
                style={{
                  backgroundColor: loading ? "#d3d3d3" : "#007bff", // Grey when loading, blue otherwise
                  color: "#fff",
                  cursor: loading ? "not-allowed" : "pointer", // Changes cursor to indicate non-clickable when loading
                  padding: "10px 20px",
                  border: "none",
                  borderRadius: "5px",
                }}
              >
                Mint
              </Button>
            </div>
          </div>
          <div className="mb-6">
            <h2 className="text-2xl font-semibold mb-4">Source of fund</h2>
            <div className="space-y-2">
              <label
                className={`block p-4 border rounded-lg transition-colors ${
                  selectedOption === "bank"
                    ? "bg-blue-50 border-blue-500"
                    : "hover:bg-gray-50"
                }`}
              >
                <input
                  type="radio"
                  name="fundOption"
                  value="bank"
                  checked={selectedOption === "bank"}
                  onChange={() => setSelectedOption("bank")}
                  className="sr-only"
                />
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-3">
                    <div className="p-2 bg-gray-100 rounded-md">
                      <CreditCard className="h-5 w-5" />
                    </div>
                    <div className="text-left">
                      <div className="font-medium">Chase ****0312</div>
                      <div className="text-sm text-gray-500">
                        $100,000.00 mint limit remaining. Assets minted will be
                        available instantly.
                      </div>
                    </div>
                  </div>
                </div>
              </label>

              <label
                className={`block p-4 border rounded-lg transition-colors ${
                  selectedOption === "external"
                    ? "bg-blue-50 border-blue-500"
                    : "hover:bg-gray-50"
                }`}
              >
                <input
                  type="radio"
                  name="fundOption"
                  value="external"
                  checked={selectedOption === "external"}
                  onChange={() => setSelectedOption("external")}
                  className="sr-only"
                />
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-3">
                    <div className="p-2 bg-gray-100 rounded-md">
                      <Landmark className="h-5 w-5" />
                    </div>
                    <div className="text-left">
                      <div className="font-medium">Capital One ****0079</div>
                      <div className="text-sm text-gray-500">
                        $10,000,000.00 mint limit remaining. Assets minted will
                        be on hold for a few days.
                      </div>
                    </div>
                  </div>
                </div>
              </label>

              <label
                className={`block p-4 border rounded-lg transition-colors ${
                  selectedOption === "solana"
                    ? "bg-blue-50 border-blue-500"
                    : "hover:bg-gray-50"
                }`}
              >
                <input
                  type="radio"
                  name="fundOption"
                  value="solana"
                  checked={selectedOption === "solana"}
                  onChange={() => setSelectedOption("solana")}
                  className="sr-only"
                />
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-3">
                    <div className="p-2 bg-gray-100 rounded-md">
                      <CreditCard className="h-5 w-5" />
                    </div>
                    <div className="text-left">
                      <div className="font-medium">SEPA ****0130</div>
                      <div className="text-sm text-gray-500">
                        €50,000.00 mint limit remaining. Assets minted will be
                        available instantly.
                      </div>
                    </div>
                  </div>
                </div>
              </label>
            </div>
          </div>
          <Button
            className="w-full"
            style={{
              width: "20vw",
              padding: "10px 20px",
              backgroundColor: "#f0f1f3", // Light gray background
              color: "#000", // Black text
              border: "none",
              borderRadius: "25px", // Rounded corners
              fontSize: "16px",
              fontWeight: "500",
              cursor: "pointer",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Optional subtle shadow
              margin: "0 auto",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Add a Payment Method
          </Button>
          <Link to="/">
            <Button className="w-full">Cancel</Button>
          </Link>
        </div>
      </main>
    </div>
  );
}
