import { useEffect, useState, useCallback } from "react";
import { Button } from "../components/ui/Button.tsx";
import { createPublicClient, custom } from "viem";
import {
  Tabs,
  TabsList,
  TabsTrigger,
  TabsContent,
} from "../components/ui/Tab.tsx";
import {
  Copy,
  Plus,
  RefreshCcw,
  Send,
  Wallet,
  User,
  Check,
  Pocket,
  CodeXml,
  StickyNote,
} from "lucide-react";
import { Link } from "react-router-dom";
import { usePrivy, useWallets } from "@privy-io/react-auth";
import { ethers, formatUnits } from "ethers";

export default function Dashboard() {
  const { ready, authenticated, user, login, logout } = usePrivy();
  const { wallets, ready: walletReady } = useWallets();

  const loggedIn = ready && authenticated;
  const [balance, setBalance] = useState(-1);
  const [showAddress, setShowAddress] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const USDC_ADDRESS = "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48"; // USDC contract address on Ethereum

  const ABI = [
    // ERC20 balanceOf function
    "function balanceOf(address owner) view returns (uint256)",
  ];

  const getWalletBalance = async () => {
    if (!walletReady || !loggedIn || wallets.length === 0) {
      setBalance(-1);
      return;
    }

    const wallet = wallets[0];
    const provider = await wallet.getEthersProvider();

    if (!wallet.address) {
      console.error("Address is null or undefined.");
      return;
    } else {
      console.log("Address is", wallet.address);
    }

    try {
      // Set up contract with USDC address, ABI, and provider
      const contract = new ethers.Contract(USDC_ADDRESS, ABI, provider);
      // Get balance and format it
      const balanceRaw = await contract.balanceOf(wallet.address);
      const balanceFormatted = formatUnits(balanceRaw, 6); // USDC uses 6 decimals

      // const balance = await client.getBalance({ address: wallet.address });
      setBalance(balanceFormatted);
      console.log("the balance is", balanceFormatted);
    } catch (error) {
      console.error("Error fetching balance:", error);
    }
  };

  // Trigger getWalletBalance when login status or wallet readiness changes
  useEffect(() => {
    if (loggedIn && walletReady) {
      getWalletBalance();
    }
  }, [loggedIn, walletReady]);

  const copyToClipboard = useCallback(() => {
    if (walletReady && wallets[0] && wallets[0].address) {
      navigator.clipboard
        .writeText(wallets[0].address)
        .then(() => {
          setIsCopied(true);
          setTimeout(() => setIsCopied(false), 2000);
        })
        .catch((err) => console.error("Failed to copy: ", err));
    }
  }, [walletReady, wallets]);

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white">
      <main className="container mx-auto p-4">
        <div className="rounded-lg border bg-white p-6 shadow-sm">
          <div className="mb-6 flex items-center space-x-4">
            <div className="flex h-12 w-12 items-center justify-center rounded-full bg-neutral-300 text-white">
              B
            </div>
            {loggedIn ? (
              <h1 className="text-2xl font-semibold text-neutral-800">
                Welcome back, {user?.google?.name}!
              </h1>
            ) : (
              <h1 className="text-2xl font-semibold text-neutral-800">
                Please <b>log in</b> to continue
              </h1>
            )}
          </div>
          <div className="mb-6">
            <div className="text-sm text-neutral-500">USDC Balance</div>
            <div className="flex items-center space-x-2">
              <span className="text-4xl font-bold">
                ${Number.parseFloat(balance).toFixed(2)}
              </span>
              <span className="text-neutral-500">USDC</span>
              <Button
                variant="outline"
                onClick={getWalletBalance}
                className="flex items-center"
              >
                <RefreshCcw className="mr-2 h-4 w-4" />
                Refresh
              </Button>
            </div>
          </div>
          <div className="mb-8 flex flex-wrap gap-2">
            <Link to="/mint" className="flex-1">
              <Button className="flex-1">
                <Plus className="mr-2 h-4 w-4" />
                Mint
              </Button>
            </Link>
            <Button variant="outline" className="flex-1">
              <Pocket className="mr-2 h-4 w-4" />
              Redeem
            </Button>
            <Button variant="outline" className="flex-1">
              Send
            </Button>
            <Button variant="outline" className="flex-1">
              Receive
            </Button>
          </div>
          <div className="relative mb-4">
            <Button
              variant="outline"
              className="absolute right-0 top-0 h-8"
              onClick={() => {
                setShowAddress(!showAddress);
                if (showAddress) {
                  copyToClipboard();
                }
              }}
            >
              {isCopied ? (
                <Check className="mr-2 h-4 w-4 text-green-500" />
              ) : (
                <Copy className="mr-2 h-4 w-4" />
              )}
              {!showAddress
                ? "Show Address"
                : isCopied
                ? "Copied!"
                : walletReady
                ? wallets[0].address
                : "Loading..."}
            </Button>
          </div>
          <Tabs defaultValue="tokens" className="w-full">
            <TabsList className="grid w-full grid-cols-3 lg:w-[400px]">
              <TabsTrigger value="tokens">Assets</TabsTrigger>

              <TabsTrigger value="activity">Transactions</TabsTrigger>
            </TabsList>
            <TabsContent value="tokens" className="mt-6">
              <div className="text-center py-12">
                <h3 className="text-lg font-semibold mb-2">
                  You don&apos;t have any assets yet!
                </h3>
                <p className="text-neutral-500 mb-4">
                  Start by buying or depositing funds:
                </p>
                <Button>
                  <Plus className="mr-2 h-4 w-4" />
                  Add Funds
                </Button>
              </div>
            </TabsContent>

            <TabsContent value="activity">
              <div className="text-center py-12">
                <h3 className="text-lg font-semibold">No recent activity</h3>
              </div>
            </TabsContent>
          </Tabs>
        </div>
      </main>
    </div>
  );
}
