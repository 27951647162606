import React from "react";
import { Button } from "../components/ui/Button.tsx";
import {
  ArrowRight,
  MessageSquare,
  ArrowLeftRight,
  BarChart2,
  Bell,
} from "lucide-react";

export default function DeveloperPage() {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <header className="mb-12">
        <h1 className="text-4xl font-bold mb-4">
          Securely integrate USDC into your business logic.
        </h1>
        <p className="text-lg text-gray-600 mb-6">
          1Stable App APIs streamline your experience by allowing you to mint,
          transfer, track transactions, and manage your account
          programmatically.
        </p>
        <p className="text-lg mb-6">
          Interested in API mint USDC? Go to{" "}
          <a href="#" className="text-blue-600 hover:underline">
            1Stable Advanced API
          </a>
        </p>
        <div className="flex space-x-4">
          <Button className="flex items-center">
            Get started
            <ArrowRight className="ml-2 h-4 w-4" />
          </Button>
          <Button variant="outline" className="flex items-center">
            Discord channel
            <MessageSquare className="ml-2 h-4 w-4" />
          </Button>
        </div>
      </header>
      <div className="grid md:grid-cols-3 gap-8">
        <div className="flex flex-col items-center text-center">
          <div className="bg-blue-100 p-4 rounded-full mb-4">
            <ArrowLeftRight className="h-8 w-8 text-blue-600" />
          </div>
          <h2 className="text-xl font-semibold mb-2">API Keys</h2>
          <p className="text-gray-600">
            Configure your Secret API Keys, Client API Key, or OAuth access
            here.
          </p>
        </div>
        <div className="flex flex-col items-center text-center">
          <div className="bg-blue-100 p-4 rounded-full mb-4">
            <BarChart2 className="h-8 w-8 text-blue-600" />
          </div>
          <h2 className="text-xl font-semibold mb-2">Track</h2>
          <p className="text-gray-600">
            Track system performance, logs, and user behaviors.
          </p>
        </div>
        <div className="flex flex-col items-center text-center">
          <div className="bg-blue-100 p-4 rounded-full mb-4">
            <Bell className="h-8 w-8 text-blue-600" />
          </div>
          <h2 className="text-xl font-semibold mb-2">Webhooks</h2>
          <p className="text-gray-600">
            Receiving webhook notifications when certain events happen.
          </p>
        </div>
      </div>
    </div>
  );
}
