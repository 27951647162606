import React from "react";
import { Button } from "../components/ui/Button.tsx";
import {
  ArrowRight,
  MessageSquare,
  ArrowLeftRight,
  BarChart2,
  Bell,
  ChevronRight,
  CreditCard,
  Menu,
  RefreshCcw,
  Wallet,
  HandCoins,
  Landmark,
  CodeXml,
  StickyNote,
  Radar,
  ScanSearch,
} from "lucide-react";

export default function CompliancePage() {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <header className="mb-12">
        <h1 className="text-4xl font-bold mb-4">
          All your stablecoin compliance need in one place.
        </h1>
        <p className="text-lg text-gray-600 mb-6">
          Manage your account's ongoing health index, compliance requirements,
          tax, and user KYC.
        </p>
        <div className="flex space-x-4">
          <Button className="flex items-center">
            Get started
            <ArrowRight className="ml-2 h-4 w-4" />
          </Button>
          <Button variant="outline" className="flex items-center">
            Discord channel
            <MessageSquare className="ml-2 h-4 w-4" />
          </Button>
        </div>
      </header>
      <div className="grid md:grid-cols-3 gap-8">
        <div className="flex flex-col items-center text-center">
          <div className="bg-blue-100 p-4 rounded-full mb-4">
            <Radar className="h-8 w-8 text-blue-600" />
          </div>
          <h2 className="text-xl font-semibold mb-2">Transaction Radar</h2>
          <p className="text-gray-600">
            Monitor transactions and report abnormal usage.
          </p>
        </div>
        <div className="flex flex-col items-center text-center">
          <div className="bg-blue-100 p-4 rounded-full mb-4">
            <ScanSearch className="h-8 w-8 text-blue-600" />
          </div>
          <h2 className="text-xl font-semibold mb-2">KYB/KYC</h2>
          <p className="text-gray-600">
            All the KYB and KYC requirements you need to know, in the most
            simplistic way.
          </p>
        </div>
        <div className="flex flex-col items-center text-center">
          <div className="bg-blue-100 p-4 rounded-full mb-4">
            <StickyNote className="h-8 w-8 text-blue-600" />
          </div>
          <h2 className="text-xl font-semibold mb-2">Tax</h2>
          <p className="text-gray-600">
            Access all tax documents you need for stablecoin transactions.
          </p>
        </div>
      </div>
    </div>
  );
}
