import "./App.css";
import TipLinkPage from "./merchant/TipLinkPage";
import MintUSDCPage from "./merchant/MintPage.tsx";
import AppPage from "./merchant/AppPage.tsx";
import DeveloperPage from "./merchant/DeveloperPage.tsx";
import CompliancePage from "./merchant/CompliancePage.tsx";
import { Button } from "./components/ui/Button.tsx";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { usePrivy, useWallets } from "@privy-io/react-auth";
import {
  RefreshCcw,
  Wallet,
  User,
  CodeXml,
  StickyNote,
  LayoutGrid,
} from "lucide-react";

function App() {
  const { ready, authenticated, user, login, logout } = usePrivy();
  const { wallets, ready: walletReady } = useWallets();
  const loggedIn = ready && authenticated;

  return (
    <Router>
      <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white">
        <header className="border-b bg-white">
          <div className="container flex h-16 items-center justify-between px-4">
            <div className="flex items-center space-x-8">
              <Link to="/" className="flex items-center space-x-2">
                <Wallet className="h-6 w-6 text-blue-600" />
                <span className="text-xl font-semibold">1Stable</span>
              </Link>
              <nav className="hidden md:flex space-x-4">
                <Link to="/">
                  <Button variant="secondary" className="h-9">
                    <Wallet className="mr-2 h-4 w-4" />
                    Wallet
                  </Button>
                </Link>
                <Link to="/app">
                  <Button variant="ghost" className="h-9">
                    <LayoutGrid className="mr-2 h-4 w-4" />
                    Apps
                  </Button>
                </Link>
                <Link to="/developer">
                  <Button variant="ghost" className="h-9">
                    <CodeXml className="mr-2 h-4 w-4" />
                    Developers
                  </Button>
                </Link>
                <Link to="/compliance">
                  <Button variant="ghost" className="h-9">
                    <StickyNote className="mr-2 h-4 w-4" />
                    Compliance
                  </Button>
                </Link>
              </nav>
            </div>
            {loggedIn ? (
              <Button
                variant="default"
                className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mt-1 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
                onClick={logout}
              >
                <User className="mr-2 h-4 w-4" />
                Logout
              </Button>
            ) : (
              <Button
                variant="default"
                className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mt-1 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
                onClick={login}
              >
                <User className="mr-2 h-4 w-4" />
                Login
              </Button>
            )}
          </div>
        </header>
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<TipLinkPage />} />
            <Route path="/mint" element={<MintUSDCPage />} />
            <Route path="/app" element={<AppPage />} />
            <Route path="/developer" element={<DeveloperPage />} />
            <Route path="/compliance" element={<CompliancePage />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
